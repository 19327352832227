import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AppSettings } from '../Types/AppSettings';
import type { RootState } from './store';

// @ts-ignore
const initialState: AppSettings = null;

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,

  reducers: {
    set: (state: Draft<AppSettings>, action: PayloadAction<AppSettings>) => {
      return action.payload;
    },
  },
});

export const { set } = appSettingsSlice.actions;
export const selectAuth = (state: RootState) => state.appSettings.auth;
export const selectTimes = (state: RootState) => state.appSettings.times;
export const selectAll = (state: RootState) => state.appSettings;
export default appSettingsSlice.reducer;
