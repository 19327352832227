import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from '../lib/axios';
import { PersonalUser } from '../Types/Users/User';
import type { RootState } from './store';
import createOneSignalSubscription from '../lib/onesignal';

interface SpoofedAdmin {
  firstName: string;
  avatar: string;
}

interface AuthState {
  user?: PersonalUser;

  spoofedAdmin?: SpoofedAdmin;

  token?: string;
  spoofedToken?: string;
}

const initialState: AuthState = {
  token: localStorage.getItem('token') || undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    login: (
      state: Draft<AuthState>,
      action: PayloadAction<{
        user: PersonalUser;
        token: string;
        remember: boolean;
      }>
    ) => {
      const newState = { ...state };
      const { user, token, remember } = action.payload;
      message.info('Logged in').then(() => {});

      newState.user = user;
      newState.token = token;
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      if (remember) localStorage.setItem('token', token);

      createOneSignalSubscription(newState.user.id, newState.user.oneSignalAuthHash).then(() => {});

      return newState;
    },
    loginToken: (state: Draft<AuthState>, action: PayloadAction<PersonalUser>) => {
      const newState = { ...state };
      message.info('Logged in').then(() => {});

      newState.user = action.payload;
      axios.defaults.headers.common.Authorization = `Bearer ${newState.token}`;

      createOneSignalSubscription(newState.user.id, newState.user.oneSignalAuthHash).then(() => {});

      return newState;
    },
    loginAsUser: (
      state: Draft<AuthState>,
      action: PayloadAction<{
        user: PersonalUser;
        token: string;
      }>
    ) => {
      const newState = { ...state };
      const { user, token } = action.payload;

      newState.spoofedAdmin = state.user;
      newState.spoofedToken = state.token;
      newState.user = user;
      newState.token = token;
      axios.defaults.headers.common.Authorization = `Bearer ${newState.token}`;

      message.info(`Logged in as ${user.firstName}`).then(() => {});

      return newState;
    },
    setUser: (state: Draft<AuthState>, action: PayloadAction<PersonalUser>) => {
      const newState = { ...state };

      newState.user = action.payload;
      return newState;
    },
    logout: (state: Draft<AuthState>) => {
      const newState = { ...state };
      axios.get('/logout').catch(() => {});

      if (newState.spoofedAdmin) {
        window.close();
        /* message.info('Returned to your account');

				state.user = state.spoofedAdmin;
				state.token = state.spoofedToken;
				axios.defaults.headers.common['Authorization'] = `Bearer ${state.spoofedToken}`;
				delete state.spoofedAdmin;
				delete state.spoofedToken; */

        return newState;
      }
      message.info('Logged out').then(() => {});

      newState.user = undefined;
      newState.token = undefined;
      axios.defaults.headers.common.Authorization = '';
      localStorage.removeItem('token');

      return newState;
    },
  },
});

export const { login, loginToken, loginAsUser, setUser, logout } = authSlice.actions;
export const selectUser = (state: RootState): PersonalUser | undefined => state.auth.user;
export const selectSpoofedAdmin = (state: RootState): SpoofedAdmin | undefined =>
  state.auth.spoofedAdmin;
export const selectToken = (state: RootState): string | undefined => state.auth.token;
export const selectAll = (state: RootState): AuthState => state.auth;
export default authSlice.reducer;
