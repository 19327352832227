import { lazy, Suspense } from 'react';
import type { AxiosError, AxiosResponse } from 'axios';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { message } from 'antd';

import './index.less';
import 'mapbox-gl/dist/mapbox-gl.css';

import store from './Redux/store';
import axios from './lib/axios';

const Customer = lazy(() => import('./Customer/App'));
const Partner = lazy(() => import('./Partner/App'));
const Driver = lazy(() => import('./Driver/App'));
const Manager = lazy(() => import('./Manager/App'));

function selectQueryData<DataType>(response: AxiosResponse<DataType> | any): DataType {
  return response.data;
}
function queryFunction({ queryKey }: { queryKey: readonly any[] }): Promise<AxiosResponse> {
  return axios.get(queryKey[0]);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: AxiosError | any) => {
        if (error?.response) {
          if (
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 422
          )
            return false;
        }

        return failureCount <= 2;
      },
      select: selectQueryData,
      queryFn: queryFunction,
      onError: (error: AxiosError | any) => {
        if (error?.response?.status === 404) return;
        message.error("Couldn't fetch data.").then(() => {});
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div className="loader" />}>
          <Switch>
            <Route path="/partner">
              <Partner />
            </Route>
            <Route path="/driver">
              <Driver />
            </Route>
            <Route path="/manager">
              <Manager />
            </Route>
            <Route path="*">
              <Customer />
            </Route>
          </Switch>
        </Suspense>
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('window')
);
