import axios, { AxiosRequestConfig } from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
  },
});

instance.interceptors.request.use(function removeAccountPrefixes(_config: AxiosRequestConfig) {
  const config = _config;

  if (config.url) {
    if (config.url.startsWith('p')) {
      config.url = config.url.slice(1);
      config.params = { scope: 'partner', ...config.params };
    }
    if (config.url.startsWith('d')) {
      config.url = config.url.slice(1);
      config.params = { scope: 'driver', ...config.params };
    }
    if (config.url.startsWith('m')) {
      config.url = config.url.slice(1);
      config.params = { scope: 'manager', ...config.params };
    }
  }

  return config;
});

export default instance;
