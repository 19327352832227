import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { ManagerInventorySettings, ManagerSettings } from '../Types/ManagerSettings';

// @ts-ignore
const initialState: ManagerSettings = null;

export const managerSettingsSlice = createSlice({
  name: 'managerSettings',
  initialState,

  reducers: {
    set: (state: Draft<ManagerSettings>, action: PayloadAction<ManagerSettings>) => {
      return action.payload;
    },
  },
});

export const { set } = managerSettingsSlice.actions;
export const selectAll = (state: RootState): ManagerSettings => state.managerSettings;
export const selectInventory = (state: RootState): ManagerInventorySettings =>
  state.managerSettings.inventory;
export default managerSettingsSlice.reducer;
