import OneSignal from 'react-onesignal';

export enum PushNotificationStatus {
  SDK_BLOCKED,
  NOT_SUPPORTED,
  DENIED,
  DEFAULT,
  ENABLED,
}

export default async function createOneSignalSubscription(userId: string, authHash: string) {
  if (!process.env.REACT_APP_ONESIGNAL_ID) return;

  await OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_ID,
    allowLocalhostAsSecureOrigin: process.env.REACT_APP_NODE_ENV === 'development',
  });
  await OneSignal.setExternalUserId(userId, authHash);
  await OneSignal.showSlidedownPrompt();
}

export function checkNotificationsEnabled(callback: (status: PushNotificationStatus) => void) {
  if (!('Notification' in window)) {
    callback(PushNotificationStatus.NOT_SUPPORTED);
    return;
  }

  OneSignal.isPushNotificationsEnabled((enabled: boolean) => {
    if (enabled) return callback(PushNotificationStatus.ENABLED);
    return callback(
      Notification.permission === 'denied'
        ? PushNotificationStatus.DENIED
        : PushNotificationStatus.DEFAULT
    );
  }).then(() => {});
}

export function forcePrompt() {
  OneSignal.showSlidedownPrompt({ force: true }).then(() => {});
}
