import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import appSettingsReducer from './appSettingsSlice';
import managerSettingsReducer from './managerSettingsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    appSettings: appSettingsReducer,

    managerSettings: managerSettingsReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
